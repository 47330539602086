import React, {PureComponent} from 'react';
import {loginStyles} from '../Modal/Modal.config';
import ModalWrapper from '../Modal/Modal';
import {
  StyledWrapper,
  Content,
  OffersBanner,
  Title,
  TextWrapper,
  OK,
  ButtonsWrapper,
} from './PopupModal.style';
import mixnsave from '../../images/offers/mixnsave.jpg';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {ANALYTICS_EVENTS} from '../../constants/constants';
export default class OffersModal extends PureComponent {
  closeModal = () => {
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.IGNORE_NEW_OFFER_POPUP);
    this.props.closeModalCallback();
  };

  processCallback = () => {
    GoogleAnalyticsHelper.trackEvent(ANALYTICS_EVENTS.VIEW_NEW_OFFER_POPUP);
    this.props.callback();
  };
  render() {
    const {showModal} = this.props;
    return (
      <ModalWrapper
        customStyles={loginStyles}
        isOpen={!!showModal}
        closeModal={this.closeModal}
        data-rw="offers modal"
      >
        <StyledWrapper>
          <Content>
            <TextWrapper>
              <Title>Have you seen our new offers?</Title>
              <OffersBanner src={mixnsave} alt="Mix and Save offers" />
              <ButtonsWrapper>
                <OK onClick={this.closeModal} data-rw="modal--ok-button">
                  Continue
                </OK>
                <OK
                  onClick={this.processCallback}
                  data-rw="modal--view-offer-button"
                >
                  View Offers
                </OK>
              </ButtonsWrapper>
            </TextWrapper>
          </Content>
        </StyledWrapper>
      </ModalWrapper>
    );
  }
}
