import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Content,
  ContentRight,
  Text,
  BoldText,
  FavoriteWrapper,
  Background,
  ColorDimmer,
  Icons,
} from './ProductBar.style';
import Favorite from '../Favorite/Favorite';
import PreviouslyPurchased from '../PreviouslyPurchased/PreviouslyPurchased';
import {connect} from 'react-redux';
import {OFFER_TYPES, SEARCH_TITLE} from '../../constants/constants';
import {PromoLink} from '../Promotion/Promotion.style';
import {getMixAndSaveUrl} from '../../helpers/search.helper';

const ProductBar = props => {
  const {
    itemId,
    name,
    offerDate,
    favourite,
    hasPreviouslyPurchased,
    auth,
    onFavoriteClick,
    productDetail,
    isNewProduct,
    hasMixOffer,
  } = props;
  const renderIsNewProduct = isNewProduct && SEARCH_TITLE.NEW;
  const renderDash = isNewProduct && offerDate && ' - ';
  const renderOffer =
    offerDate &&
    `${isNewProduct ? 'Introductory ' : ''} Offer ends ${offerDate}`;
  const renderMixNSaveTag =
    (productDetail && productDetail.offer && productDetail.offer.promoTag) ||
    OFFER_TYPES.MIX;
  const renderMixNSavePromoTagId =
    productDetail && productDetail.offer && productDetail.offer.promoTagId;
  const renderMixNSaveLink = hasMixOffer && (
    <PromoLink
      to={getMixAndSaveUrl(renderMixNSaveTag, renderMixNSavePromoTagId)}
    >
      <BoldText>{renderMixNSaveTag}</BoldText>
    </PromoLink>
  );
  const renderTitle = (
    <BoldText>
      {renderIsNewProduct}
      {renderDash}
      {!hasMixOffer && renderOffer}
    </BoldText>
  );
  const renderPreviouslyPurchased = hasPreviouslyPurchased && (
    <PreviouslyPurchased $isOffer={!!offerDate} />
  );
  const favouriteClick = () => onFavoriteClick(productDetail);
  const renderFavourite = !!auth && (
    <FavoriteWrapper onClick={favouriteClick}>
      <Favorite isOffer={!!offerDate} isFavorite={!!favourite} />
    </FavoriteWrapper>
  );
  return (
    <Wrapper data-rw="product--bar">
      <Content>
        <BoldText>{itemId}</BoldText>
        <Text>{name}</Text>
      </Content>
      <ContentRight>
        {renderMixNSaveLink}
        {renderTitle}
        <Icons>
          {renderPreviouslyPurchased}
          {renderFavourite}
        </Icons>
      </ContentRight>
      <ColorDimmer $isOffer={!!offerDate} />
      <Background />
    </Wrapper>
  );
};

ProductBar.propTypes = {
  itemId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  offerDate: PropTypes.string,
  favourite: PropTypes.bool,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ProductBar);
